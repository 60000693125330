import React from "react";
import { Link } from "react-router-dom";
type Props = {
  title: string;
};

const BtnServicesDark = ({ title }: Props) => {
  return (
    <Link to="/order">
      <div className="w-48 h-12 bg-gradient-to-l from-green-500 via-black to-black relative group">
        <button className="w-48 h-12 bg-reactYellowLight text-black font-titleFont font-semibold text-lg absolute -translate-x-2 -translate-y-2 transform group-hover:bg-reactYellow group-hover:translate-x-0 group-hover:translate-y-0 transition-transform duration-500">
          {title}
        </button>
      </div>
    </Link>
  );
};

export default BtnServicesDark;
