import React from "react";
import {
  FaLinkedinIn,
  FaYoutube,
  FaInstagram,
  FaFacebookF,
} from "react-icons/fa";
import { SiGithub, SiDiscord } from "react-icons/si";

const BottomFooter = () => {
  return (
    <div className="w-full mdl:max-w-[600px] mx-auto border-t-[1px] border-gray-400 py-8 flex flex-col gap-4 items-center justify-center">
      <div className="flex items-center gap-2 mdl:gap-3">
        <a
          href="https://www.youtube.com/@reactjsBD"
          target="_blank"
          rel="noreferrer"
        >
          <span className="w-8 h-8 text-base mdl:w-10 mdl:h-10 mdl:text-xl rounded-full inline-flex items-center justify-center border-[2px] border-zinc-400 hover:bg-[#cd201f] hover:border-[#cd201f] duration-300 cursor-pointer">
            <FaYoutube />
          </span>
        </a>
        <a
          href="https://github.com/noorjsdivs"
          target="_blank"
          rel="noreferrer"
        >
          <span className="w-8 h-8 text-base mdl:w-10 mdl:h-10 mdl:text-xl rounded-full inline-flex items-center justify-center border-[2px] border-zinc-400 hover:bg-[#00405d] hover:border-[#00405d] duration-300 cursor-pointer">
            <SiGithub />
          </span>
        </a>
        <a
          href="https://www.instagram.com/simplenoor143/"
          target="_blank"
          rel="noreferrer"
        >
          <span className="w-8 h-8 text-base mdl:w-10 mdl:h-10 mdl:text-xl rounded-full inline-flex items-center justify-center border-[2px] border-zinc-400 hover:bg-[#C13584] hover:border-[#C13584] duration-300 cursor-pointer">
            <FaInstagram />
          </span>
        </a>
        <a
          href="https://www.facebook.com/Noorlalu143/"
          target="_blank"
          rel="noreferrer"
        >
          <span className="w-8 h-8 text-base mdl:w-10 mdl:h-10 mdl:text-xl rounded-full inline-flex items-center justify-center border-[2px] border-zinc-400 hover:bg-[#3b5998] hover:border-[#3b5998] duration-300 cursor-pointer">
            <FaFacebookF />
          </span>
        </a>
        <a
          href="https://www.linkedin.com/in/noor-mohammad-ab2245193/"
          target="_blank"
          rel="noreferrer"
        >
          <span className="w-8 h-8 text-base mdl:w-10 mdl:h-10 mdl:text-xl rounded-full inline-flex items-center justify-center border-[2px] border-zinc-400 hover:bg-[#0077b5] hover:border-[#0077b5] duration-300 cursor-pointer">
            <FaLinkedinIn />
          </span>
        </a>
        <a
          href="https://discord.com/channels/@me"
          target="_blank"
          rel="noreferrer"
        >
          <span className="w-8 h-8 text-base mdl:w-10 mdl:h-10 mdl:text-xl rounded-full inline-flex items-center justify-center border-[2px] border-white hover:bg-[#5865F2] hover:border-[#5865F2] duration-300 cursor-pointer">
            <SiDiscord />
          </span>
        </a>
      </div>
      <p className="text-xs mdl:text-sm text-center text-gray-300">
        © Copyright 2023 by ReactBD. All Rights Reserved.
      </p>
    </div>
  );
};

export default BottomFooter;
