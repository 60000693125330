import React from "react";
import { motion } from "framer-motion";
import { orderImg } from "../../assets";

const Order = () => {
  return (
    <div className="bg-white text-black">
      <div className="max-w-screen-xl bg-white mx-auto relative py-10 lgl:py-16 px-6">
        <div className="w-full flex items-center gap-6">
          <div className="w-1/2 flex flex-col justify-between gap-16">
            <div>
              <motion.h1
                initial={{ x: -60, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                  x: { type: "spring", stiffness: 60 },
                  ease: "easeIn",
                  duration: 0.5,
                }}
                className="text-zinc-800 uppercase text-sm font-medium"
              >
                <span className="text-reactYellow hover:underline underline-offset-2 duration-200">
                  Home{" "}
                </span>
                / Order
              </motion.h1>
            </div>
            <motion.div
              initial={{ x: -30, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                x: { type: "spring", stiffness: 60 },
                ease: "easeIn",
                duration: 1,
              }}
            >
              <div className="relative">
                <h2 className="text-3xl md:text-[40px] text-black font-semibold flex items-center">
                  <span className="text-7xl text-reactYellow">O</span> rder
                </h2>
                <p className="max-w-[600px] text-base text-zinc-800 font-semibold">
                  It will be our pleasure to commit to a long-term cooperation
                  and partnership.
                </p>
              </div>
            </motion.div>
            <div className="flex gap-6">
              <button className="w-44 h-11 bg-white hover:bg-reactYellow text-black rounded-full text-base font-semibold border border-black duration-300 hover:border-transparent">
                Website
              </button>
              <button className="w-44 h-11 bg-white hover:bg-reactYellow text-black rounded-full text-base font-semibold border border-black duration-300 hover:border-transparent">
                Graphic Design
              </button>
              <button className="w-44 h-11 bg-white hover:bg-reactYellow text-black rounded-full text-base font-semibold border border-black duration-300 hover:border-transparent">
                E-marketing
              </button>
            </div>
          </div>
          <div className="w-1/2">
            <motion.img
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                delay: 0.4,
                x: { type: "spring", stiffness: 120 },

                ease: "easeIn",
                duration: 1,
              }}
              className="w-full h-96 object-cover"
              src={orderImg}
              alt="orderImg"
            />
          </div>
        </div>
        {/* ================= Order form Start here ================ */}
        <form className="max-w-4xl mt-10 flex flex-col gap-6">
          <h1 className="font-titleFont text-xl text-zinc-800">
            Please fill in the required fields (*)
          </h1>
          <div>
            <div className="flex gap-6">
              <input
                className="w-full h-12 rounded-full px-4 border"
                type="text"
                placeholder="Full name *"
              />
              <input
                className="w-full h-12 rounded-full px-4 border"
                type="text"
                placeholder="Company"
              />
            </div>
          </div>
        </form>
        {/* ================= Order form End here ================== */}
      </div>
    </div>
  );
};

export default Order;
