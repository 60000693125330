import React from "react";
import { projectList } from "../../constants";

const Projects = () => {
  return (
    <div className="w-full py-20 px-4 bg-black text-white">
      <div className="max-w-screen-xl mx-auto">
        <h2 className="text-3xl uppercase font-medium tracking-wide mb-16 font-titleFont text-center">
          OUR WEBSITE DEVELOPMENT PORTFOLIO
        </h2>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 lgl:gap-16">
          {projectList.map((item) => (
            <a key={item._id} rel="noreferrer" href={item.link} target="_blank">
              <div className="w-full h-auto border-[1px] border-gray-600 hover:border-reactYellow border-opacity-30 hover:border-opacity-10">
                <div className="w-full h-80 flex flex-col group overflow-hidden">
                  <div className="overflow-hidden relative">
                    <img
                      className="w-full h-[1300px] object-cover cursor-pointer top-0 -translate-y-[0] group-hover:-translate-y-[950px] transition-translate transition-ease-in duration-[5s]"
                      src={item.image}
                      loading="lazy"
                      alt="projectOneImg"
                    />
                    {/* ================= image design start here ================ */}
                    <span className="w-1 h-8 bg-reactYellow inline-block absolute top-0 left-0  transition-translate duration-500 -translate-x-1 group-hover:-translate-x-0"></span>
                    <span className="w-1 h-8 bg-reactYellow inline-block absolute bottom-0 left-0  transition-translate duration-500 -translate-x-1 group-hover:-translate-x-0"></span>
                    <span className="w-1 h-8 bg-reactYellow inline-block absolute bottom-0 right-0  transition-translate duration-500 translate-x-2 group-hover:translate-x-0"></span>
                    <span className="w-1 h-8 bg-reactYellow inline-block absolute top-0 right-0  transition-translate duration-500 translate-x-1 group-hover:translate-x-0"></span>
                    <span className="w-8 h-1 bg-reactYellow inline-block absolute top-0 right-0  transition-translate duration-500 -translate-y-1 group-hover:-translate-y-0"></span>
                    <span className="w-8 h-1 bg-reactYellow inline-block absolute top-0 left-0  transition-translate duration-500 -translate-y-1 group-hover:-translate-y-0"></span>
                    <span className="w-8 h-1 bg-reactYellow inline-block absolute bottom-0 left-0  transition-translate duration-500 translate-y-1 group-hover:translate-y-0"></span>
                    <span className="w-8 h-1 bg-reactYellow inline-block absolute bottom-0 right-0  transition-translate duration-500 translate-y-1 group-hover:translate-y-0"></span>
                    {/* ================= image design end here ================ */}
                  </div>
                  <div>
                    <p className="font-titleFont overflow-hidden text-sm text-[#737373] h-7 w-full px-2 py-1 mt-4 relative font-normal group-hover:text-white duration-500 cursor-pointer">
                      {item.title}
                      <span className="w-[1px] h-2 bg-[#737373] inline-block absolute bottom-0 left-0 -translate-x-[.5px] group-hover:bg-reactYellow group-hover:translate-x-0 transition-transform duration-500"></span>
                      <span className="w-full h-[1px] bg-[#737373] inline-block absolute bottom-0 left-0 translate-y-[.5px] group-hover:bg-reactYellow group-hover:translate-y-0 transition-transform duration-500"></span>
                    </p>
                  </div>
                </div>
                <div className="p-2">
                  <p className="w-full text-base text-[#737373] hover:text-gray-300 duration-300">
                    {item.description}
                  </p>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
