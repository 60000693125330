import React from "react";
import { MdCircle } from "react-icons/md";

type Props = {
  title: string;
};

const ListItems = ({ title }: Props) => {
  return (
    <li className="flex items-start gap-2 text-gray-300 text-base font-titleFont tracking-wide hover:text-white duration-200 group">
      <span className="text-sm text-reactYellowLight group-hover:text-reactYellow duration-200 mt-1">
        <MdCircle />
      </span>
      {title}
    </li>
  );
};

export default ListItems;
