import React from "react";
import Marquee from "react-fast-marquee";
import {
  reactLogo,
  nodejsLogo,
  mongodbLogo,
  mysqlLogo,
  wordpressLogo,
  oracleLogo,
  shopifyLogo,
} from "../assets/index";

const IconMarquee = () => {
  return (
    <div className="w-full bg-black bg-gradient-to-tr from-reactYellow to-reactYellowLight">
      <Marquee
        speed={70}
        pauseOnHover={false}
        gradientColor={[255, 195, 0]}
        gradientWidth={50}
        className="max-w-screen-2xl mx-auto flex gap-3 overflow-hidden bg-black"
      >
        <img
          src={reactLogo}
          alt="reacLogo"
          className="brightness-50 hover:brightness-100 duration-300"
        />
        <img
          src={nodejsLogo}
          alt="nodejsLogo"
          className="brightness-50 hover:brightness-100 duration-300"
        />
        <img
          src={mongodbLogo}
          alt="mongodbLogo"
          className="brightness-50 hover:brightness-100 duration-300"
        />
        <img
          src={mysqlLogo}
          alt="mysqlLogo"
          className="brightness-50 hover:brightness-100 duration-300"
        />
        <img
          src={wordpressLogo}
          alt="wordpressLogo"
          className="brightness-50 hover:brightness-100 duration-300"
        />
        <img
          src={oracleLogo}
          alt="oracleLogo"
          className="brightness-50 hover:brightness-100 duration-300"
        />
        <img
          src={shopifyLogo}
          alt="shopifyLogo"
          className="brightness-50 hover:brightness-100 duration-300"
        />
        <img
          src={reactLogo}
          alt="reactLogo"
          className="brightness-50 hover:brightness-100 duration-300"
        />
        <img
          src={nodejsLogo}
          alt="nodejsLogo"
          className="brightness-50 hover:brightness-100 duration-300"
        />
      </Marquee>
    </div>
  );
};

export default IconMarquee;
