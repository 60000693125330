import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";
import Prime from "./Prime";
import Navbar from "./components/Navbar";
import Footer from "./components/footer/Footer";
import AboutUs from "./pages/aboutUs/AboutUs";
import Faqpage from "./pages/faq/Faqpage";
import Contact from "./pages/contact/Contact";
import ServicesNav from "./components/servicesProps/ServicesNav";
import WebDevelopment from "./components/servicesProps/WebDevelopment";
import WebDesign from "./components/servicesProps/WebDesign";
import GraphicDesign from "./components/servicesProps/GraphicDesign";
import SeoServices from "./components/servicesProps/SeoServices";
import Order from "./pages/orders/Order";

// ============== Creating Router Start here ===================

const Layout = () => {
  return (
    <div>
      <Navbar />
      <ScrollRestoration />
      <Outlet />
      <Footer />
    </div>
  );
};

const ServiesLayout = () => {
  return (
    <div>
      <ServicesNav />
      <ScrollRestoration />
      <Outlet />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      {/* ========== Root Directory Start here =========== */}
      <Route path="/" element={<Layout />}>
        <Route index element={<Prime />}></Route>
        <Route path="/aboutus" element={<AboutUs />}></Route>
        <Route path="/faq" element={<Faqpage />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/order" element={<Order />}></Route>
      </Route>
      {/* ========== Root Directory End here ============= */}
      {/* ========== Services Directory Start here ======= */}
      <Route path="/services" element={<ServiesLayout />}>
        <Route
          path="/services/webdevelopment"
          index
          element={<WebDevelopment />}
        ></Route>
        <Route path="/services/webdesign" element={<WebDesign />}></Route>
        <Route
          path="/services/graphicdesign"
          element={<GraphicDesign />}
        ></Route>
        <Route path="/services/seo" element={<SeoServices />}></Route>
      </Route>
      {/* ========== Services Directory End here ========= */}
    </Route>
  )
);
// ============== Creating Router End here =====================
function App() {
  return (
    <div className="font-bodyFont text-white bg-black">
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;
