import React from "react";
interface Props {
  title: string;
}

const FooterTitle = ({ title }: Props) => {
  return (
    <h2 className="text-xl font-medium text-gray-200 uppercase tracking-wide mb-4">
      {title}
    </h2>
  );
};

export default FooterTitle;
