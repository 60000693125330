import React, { FunctionComponent } from "react";

interface Props {
  techTitle: string;
  Icon: React.ElementType;
  className: string;
}

const TechItem: FunctionComponent<Props> = ({ techTitle, Icon, className }) => {
  return (
    <div className="flex flex-col items-center">
      <span className={className}>
        <Icon />
      </span>
      <p className="text-lg text-gray-200 font-medium">{techTitle}</p>
    </div>
  );
};

export default TechItem;
