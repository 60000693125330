import React from "react";
import { HiGlobeAlt } from "react-icons/hi";
import { FaEnvelope, FaHome, FaMobile } from "react-icons/fa";
import { footerQuickLinks, footerServicesList } from "../../constants";
import { logoDark } from "../../assets";
import FooterTitle from "./FooterTitle";
import BottomFooter from "./BottomFooter";

const Footer = () => {
  return (
    <div className="w-full bg-footer-bg bg-center bg-cover bg-no-repeat py-10 px-4">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-4 gap-10 lgl:gap-4 lgl:place-items-center mb-10">
        {/* =================== Free Quote Start here ================ */}
        <div className="h-full">
          <FooterTitle title="Get a Free Quote" />
          <div className="flex flex-col gap-1 text-base font-light text-gray-100 pb-4 border-b-[1px] border-gray-600">
            <p>24X7 Helpline:</p>
            <p>+968 97859628</p>
          </div>
          <div className="py-4 text-gray-100 font-light flex flex-col gap-1">
            <a href="https://reactbd.com/" target="_blank" rel="noreferrer">
              <p className="flex items-center gap-1 hover:text-reactYellow duration-300">
                <HiGlobeAlt className="text-lg text-gray-300" /> ReactBD
                Technology
              </p>
            </a>
            <a href="mailto:reactbd@proton.me">
              <p className="flex items-center gap-1 hover:text-reactYellow duration-300">
                <FaEnvelope className="text-base text-gray-300" />
                reactbd@proton.me
              </p>
            </a>
            <a href="mailto:reactjsbd@gmail.com">
              <p className="flex items-center gap-1 hover:text-reactYellow duration-300">
                <FaEnvelope className="text-base text-gray-300" />
                reactjsbd@gmail.com
              </p>
            </a>
          </div>
          <img className="w-32" src={logoDark} alt="logoDark" />
        </div>
        {/* =================== Free Quote End here== ================ */}
        {/* =================== Services Start here ================== */}
        <div className="h-full">
          <FooterTitle title="SERVICES" />
          <ul className="flex flex-col gap-2">
            {footerServicesList.map((item) => (
              <li
                className="text-gray-200 text-base font-light hover:text-reactYellow duration-300 cursor-pointer"
                key={item._id}
              >
                {item.title}
              </li>
            ))}
          </ul>
        </div>
        {/* =================== Services End here ==================== */}
        {/* =================== Quick Links Start here =============== */}
        <div className="h-full w-full">
          <FooterTitle title="QUICK LINKS" />
          <ul className="flex flex-col gap-2">
            {footerQuickLinks.map((item) => (
              <li
                className="text-gray-200 text-base font-light hover:text-reactYellow duration-300 cursor-pointer"
                key={item._id}
              >
                {item.title}
              </li>
            ))}
          </ul>
        </div>
        {/* =================== Quick Links End here ================= */}
        {/* =================== Contact Us Start here ================ */}
        <div className="h-full w-full">
          <FooterTitle title="CONTACT US" />
          <div className="flex flex-col gap-3">
            <h3 className="text-lg font-medium text-gray-200">Bangladesh:</h3>
            <div className="flex flex-col gap-2">
              <div className="text-base font-light text-gray-300 hover:text-gray-50 duration-200 flex items-start gap-2">
                <p className="text-reactYellow text-lg mt-1">
                  <FaHome />
                </p>
                <p>Rupnogor Abashik Area, Mirpur-1, Dhaka.</p>
              </div>
              <div className="text-base font-light text-gray-300 hover:text-gray-50 flex items-start gap-2">
                <p className="text-reactYellow text-lg mt-1">
                  <FaEnvelope />
                </p>
                <p>reactjsbd@gmail.com</p>
              </div>
              <div className="text-base font-light text-gray-300 hover:text-gray-50 flex items-start gap-2">
                <p className="text-reactYellow text-lg mt-1">
                  <FaEnvelope />
                </p>
                <p>reactbd@proton.me</p>
              </div>
              <div className="text-base font-light text-gray-300 hover:text-gray-50 flex items-start gap-2">
                <p className="text-reactYellow text-lg mt-1">
                  <FaMobile />
                </p>
                <p>+968 97859628</p>
              </div>
            </div>
            <h3 className="text-lg font-medium text-gray-200 mt-1">Oman:</h3>
            <div className="flex flex-col gap-2">
              <div className="text-base font-light text-gray-300 hover:text-gray-50 flex items-start gap-2">
                <p className="text-reactYellow text-lg mt-1">
                  <FaHome />
                </p>
                <p>Muscat Business Area, Ruwi, Muscat, Sultanate of Oman</p>
              </div>
              <div className="text-base font-light text-gray-300 hover:text-gray-50 flex items-start gap-2">
                <p className="text-reactYellow text-lg mt-1">
                  <FaEnvelope />
                </p>
                <p>reactjsbd@gmail.com</p>
              </div>
              <div className="text-base font-light text-gray-300 hover:text-gray-50 flex items-start gap-2">
                <p className="text-reactYellow text-lg mt-1">
                  <FaMobile />
                </p>
                <p>+968 97859628</p>
              </div>
            </div>
          </div>
        </div>
        {/* =================== Contact Us End here ================== */}
      </div>
      <BottomFooter />
    </div>
  );
};

export default Footer;
