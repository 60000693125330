import React from "react";
import Banner from "./components/Banner";
import Faq from "./components/faq/Faq";
import IconMarquee from "./components/IconMarquee";
import Insights from "./components/Insights";
import Projects from "./components/projects/Projects";
import Tech from "./components/tech/Tech";
import WhatWeOffer from "./components/whatweoffer/WhatWeOffer";

const Prime = () => {
  return (
    <div>
      <Banner />
      <IconMarquee />
      <WhatWeOffer />
      <Tech />
      <Insights />
      <Projects />
      <Faq />
    </div>
  );
};

export default Prime;
