import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import { HiArrowNarrowRight } from "react-icons/hi";
import { FaChevronDown } from "react-icons/fa";
import { MdOutlineCircle, MdOutlineClose } from "react-icons/md";
import { logoDarkImg } from "../assets/index";
import { servicesList } from "../constants/index";
import { Link, NavLink } from "react-router-dom";
import BottomFooter from "./footer/BottomFooter";

const Navbar = () => {
  const ref = useRef<string | any>("");
  const [enquirySidebar, setEnquirySidebar] = useState(false);
  // ================ Input value Start here =================
  const [clientname, setClientname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [services, setServices] = useState("");
  const [message, setMessage] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  // ================ Input value End here ===================
  // ================ Error Messages Start here ==============
  const [clientnameErr, setClientnameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [phoneNumberErr, setPhoneNumberErr] = useState("");
  const [servicesErr, setServicesErr] = useState("");
  const [messageErr, setMessageErr] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  // ================ Error Messages End here ================

  // ================ onChange value Start here ===============
  const handleClientname = (e: any) => {
    setClientname(e.target.value);
    setClientnameErr("");
  };
  const handleEmail = (e: any) => {
    setEmail(e.target.value);
    setEmailErr("");
  };
  const handleServices = (e: any) => {
    setServices(e.target.value);
    setServicesErr("");
  };
  const handlePhoneNumber = (e: any) => {
    setPhoneNumber(e.target.value);
    setPhoneNumberErr("");
  };
  const handleMessage = (e: any) => {
    setMessage(e.target.value);
    setMessageErr("");
  };

  // Email validation start
  const emailValidation = (email: any) => {
    return String(email)
      .toUpperCase()
      .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  };
  // ================ onChange value End here =================
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!clientname) {
      setClientnameErr("Enter your Name");
    }
    if (!email) {
      setEmailErr("Enter your Email");
    } else {
      if (!emailValidation(email)) {
        setEmailErr("Enter a valid Email");
      }
    }
    if (!services) {
      setServicesErr("Selecet a Service");
    }
    if (!phoneNumber) {
      setPhoneNumberErr("Enter your Phone Number");
    }
    if (!message) {
      setMessageErr("Enter your Messages");
    }
    if (
      clientname &&
      email &&
      services &&
      emailValidation(email) &&
      phoneNumber &&
      message
    ) {
      setSuccessMsg(
        `Thank you dear ${clientname} for your valuable time and choosing our ${services} service. Our Marketing team will join with you shortly and additional details will send to you by your email at ${email}. Have a great day ahead.`
      );
    }
  };
  function handleClick(e: any) {
    if (e.target.contains(ref.current)) {
      // do something with myRef.current
      setEnquirySidebar(false);
    }
  }

  return (
    <div className="w-full bg-black h-20 sticky top-0 z-50 px-4">
      <div className="max-w-screen-xl h-full mx-auto flex gap-6 lg:gap-0 justify-between items-center">
        <Link to="/">
          <div>
            <img className="w-44" src={logoDarkImg} alt="logo" />
          </div>
        </Link>
        <div
          onClick={() => setShowMenu(true)}
          className="w-6 h-5 flex flex-col justify-between items-center mdl:hidden text-4xl text-reactYellow cursor-pointer overflow-hidden group"
        >
          {/* <HiOutlineMenu /> */}
          <span className="w-full h-[2px] bg-reactYellow inline-flex transform group-hover:translate-x-2 transition-all ease-in-out duration-300"></span>
          <span className="w-full h-[2px] bg-reactYellow inline-flex transform translate-x-3 group-hover:translate-x-0 transition-all ease-in-out duration-300"></span>
          <span className="w-full h-[2px] bg-reactYellow inline-flex transform translate-x-1 group-hover:translate-x-3 transition-all ease-in-out duration-300"></span>
        </div>
        {/* ========================= Small NavBar Start here ====================== */}
        {showMenu && (
          <div className="absolute mdl:hidden top-0 right-0 w-full md:w-[60%] h-screen overflow-y-scroll bg-black flex flex-col items-center">
            <div
              onClick={() => setShowMenu(false)}
              className=" w-full flex items-center justify-between p-6"
            >
              <Link to="/">
                <img className="w-32" src={logoDarkImg} alt="logo" />
              </Link>
              <MdOutlineClose className="text-3xl text-reactYellowLight cursor-pointer hover:text-reactYellow" />
            </div>
            <motion.div
              initial={{ y: -30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="w-[80%] md:w-[60%] bg-black h-full p-6 flex flex-col gap-6"
            >
              <ul className="flex flex-col items-start gap-4 uppercase text-sm font-titleFont font-medium tracking-wider text-gray-300">
                <Link className="w-full" to="/">
                  <li
                    onClick={() => setShowMenu(false)}
                    className="w-full border-b-[1px] border-b-zinc-600 hover:border-b-zinc-400 py-2 flex items-center gap-3 hover:text-reactYellow cursor-pointer duration-300 group"
                  >
                    <MdOutlineCircle className="text-zinc-400 text-xs bg-transparent rounded-full group-hover:text-reactYellow duration-300" />
                    Home
                  </li>
                </Link>
                <Link className="w-full" to="/aboutus">
                  <li
                    onClick={() => setShowMenu(false)}
                    className="w-full border-b-[1px] border-b-zinc-600 hover:border-b-zinc-400 py-2 flex items-center gap-3 hover:text-reactYellow cursor-pointer duration-300 group"
                  >
                    <MdOutlineCircle className="text-zinc-400 text-xs bg-transparent rounded-full group-hover:text-reactYellow duration-300" />
                    About us
                  </li>
                </Link>

                <Link className="w-full" to="/services/webdevelopment">
                  <li
                    onClick={() => setShowMenu(false)}
                    className="w-full border-b-[1px] border-b-zinc-600 hover:border-b-zinc-400 py-2 flex items-center gap-3 hover:text-reactYellow cursor-pointer duration-300 group"
                  >
                    <MdOutlineCircle className="text-zinc-400 text-xs bg-transparent rounded-full group-hover:text-reactYellow duration-300" />
                    Services
                  </li>
                </Link>
                <Link className="w-full" to="/faq">
                  <li
                    onClick={() => setShowMenu(false)}
                    className="w-full border-b-[1px] border-b-zinc-600 hover:border-b-zinc-400 py-2 flex items-center gap-3 hover:text-reactYellow cursor-pointer duration-300 group"
                  >
                    <MdOutlineCircle className="text-zinc-400 text-xs bg-transparent rounded-full group-hover:text-reactYellow duration-300" />
                    FAQ
                  </li>
                </Link>
                <Link className="w-full" to="/contact">
                  <li
                    onClick={() => setShowMenu(false)}
                    className="w-full border-b-[1px] border-b-zinc-600 hover:border-b-zinc-400 py-2 flex items-center gap-3 hover:text-reactYellow cursor-pointer duration-300 group"
                  >
                    <MdOutlineCircle className="text-zinc-400 text-xs bg-transparent rounded-full group-hover:text-reactYellow duration-300" />
                    Contact
                  </li>
                </Link>
              </ul>
              <div>
                <button
                  onClick={() => setEnquirySidebar(true)}
                  className="text-sm font-titleFont font-medium uppercase bg-reactYellowLight text-black w-28 h-10 rounded-md hover:bg-reactYellow duration-300 flex items-center justify-center group"
                >
                  Enquiry
                  <span className="text-sm translate-x-.5 group-hover:translate-x-2 transition-transform duration-300">
                    <HiArrowNarrowRight />
                  </span>
                </button>
              </div>
              <BottomFooter />
            </motion.div>
          </div>
        )}
        {/* ========================= Small NavBar End here ======================== */}
        <div className="w-full lg:w-2/3 hidden mdl:flex justify-between item-center">
          <ul className="flex items-center gap-10 uppercase text-sm font-titleFont font-medium tracking-wider text-gray-300">
            <NavLink to="/">
              <li className="hover:text-white cursor-pointer duration-300">
                Home
              </li>
            </NavLink>
            <NavLink to="/aboutus">
              <li className="hover:text-white cursor-pointer duration-300">
                About us
              </li>
            </NavLink>
            <NavLink to="/services/webdevelopment">
              <li className="hover:text-white cursor-pointer duration-300">
                Services
              </li>
            </NavLink>
            <NavLink to="/faq">
              <li className="hover:text-white cursor-pointer duration-300">
                FAQ
              </li>
            </NavLink>
            <NavLink to="/contact">
              <li className="hover:text-white cursor-pointer duration-300">
                Contact
              </li>
            </NavLink>
          </ul>
          <div>
            <button
              onClick={() => setEnquirySidebar(true)}
              className="text-sm font-titleFont font-medium uppercase bg-reactYellowLight text-black w-28 mdl:w-24 lgl:w-28 h-10 rounded-md hover:bg-reactYellow duration-300 flex items-center justify-center group"
            >
              Enquiry
              <span className="text-sm translate-x-.5 group-hover:translate-x-2 transition-transform duration-300">
                <HiArrowNarrowRight />
              </span>
            </button>
          </div>
        </div>
      </div>

      {/* ==================== EnquirySidebar Start here ================= */}
      {enquirySidebar && (
        <div
          ref={(node) => (ref.current = node)}
          onClick={handleClick}
          className="fixed top-0 left-0 w-full h-screen bg-black bg-opacity-30 flex justify-end overflow-scroll scrollbar-hide z-50"
        >
          <motion.div
            initial={{ x: 400, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="w-[700px] h-full bg-[#212121] pl-28 relative flex items-center justify-center"
          >
            <div className="w-full h-[94%] scrollbar-hide flex flex-col gap-10 justify-start overflow-y-scroll">
              {successMsg ? (
                <motion.p
                  initial={{ y: 50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                  className="text-lg h-full flex items-center text-green-400 pr-10 leading-6 font-titleFont font-medium"
                >
                  {successMsg}
                </motion.p>
              ) : (
                <>
                  <div>
                    <p className="font-titleFont text-5xl text-white font-semibold">
                      Got a Question?
                    </p>
                    <p className="text-white text-lg font-medium mt-4">
                      We are always ready to assist you!
                    </p>
                  </div>
                  <form className="pr-16 flex flex-col gap-6">
                    <input
                      onChange={handleClientname}
                      className={`inputField ${
                        clientnameErr && "border-red-600 shadow-inputShadow"
                      }`}
                      type="text"
                      placeholder="Your Full Name*"
                    />
                    {clientnameErr && (
                      <p className="w-full text-red-500 px-4 -mt-3 text-sm font-medium">
                        <span className="font-bold italic mr-1">!</span>{" "}
                        {clientnameErr}
                      </p>
                    )}
                    <input
                      onChange={handleEmail}
                      className={`inputField ${
                        emailErr && "border-red-600 shadow-inputShadow"
                      }`}
                      type="email"
                      placeholder="Your Email Address*"
                    />
                    {emailErr && (
                      <p className="w-full text-red-500 px-4 -mt-3 text-sm font-medium">
                        <span className="font-bold italic mr-1">!</span>{" "}
                        {emailErr}
                      </p>
                    )}
                    <input
                      onChange={handlePhoneNumber}
                      className={`inputField ${
                        phoneNumberErr && "border-red-600 shadow-inputShadow"
                      }`}
                      type="text"
                      placeholder="Your Phone Number*"
                    />
                    {phoneNumberErr && (
                      <p className="w-full text-red-500 px-4 -mt-3 text-sm font-medium">
                        <span className="font-bold italic mr-1">!</span>{" "}
                        {phoneNumberErr}
                      </p>
                    )}

                    <div
                      className={`w-full py-3 bg-white rounded-md relative cursor-pointer ${
                        servicesErr && "border-red-600 shadow-inputShadow"
                      }`}
                    >
                      <select
                        onChange={handleServices}
                        className="appearance-none w-full px-4 outline-none text-gray-700 font-medium"
                      >
                        {servicesList.map((item) => (
                          <option key={item._id}>{item.title}</option>
                        ))}
                      </select>
                      <span className="absolute top-4 text-gray-600 text-base right-6 cursor-pointer">
                        <FaChevronDown />
                      </span>
                    </div>
                    {servicesErr && (
                      <p className="w-full text-red-500 px-4 -mt-3 text-sm font-medium">
                        <span className="font-bold italic mr-1">!</span>{" "}
                        {servicesErr}
                      </p>
                    )}
                    <textarea
                      onChange={handleMessage}
                      className={`inputField ${
                        clientnameErr && "border-red-600 shadow-inputShadow"
                      } h-28 resize-none`}
                      placeholder="Brief Your Projects"
                    ></textarea>
                    {messageErr && (
                      <p className="w-full text-red-500 px-4 -mt-3 text-sm font-medium">
                        <span className="font-bold italic mr-1">!</span>{" "}
                        {messageErr}
                      </p>
                    )}
                    <button
                      onClick={handleSubmit}
                      className="text-base font-titleFont font-semibold uppercase text-reactYellow border-[1px] border-gray-600 w-full h-12 flex items-center gap-1 justify-center relative group overflow-hidden active:bg-reactYellow active:text-white"
                    >
                      Submit
                      <span className="text-sm translate-x-.5 group-hover:translate-x-2 transition-transform duration-300">
                        <HiArrowNarrowRight />
                      </span>
                      <span className="w-full h-[1px] bg-reactYellow absolute left-0 top-0 inline-flex -translate-x-[100%] group-hover:translate-x-0 duration-300"></span>
                      <span className="w-full h-[1px] bg-reactYellow absolute left-0 bottom-0 inline-flex translate-x-[100%] group-hover:translate-x-0 duration-300"></span>
                      <span className="w-[2px] h-full bg-reactYellow absolute left-0 top-0 inline-flex translate-y-[100%] group-hover:translate-y-0 duration-500"></span>
                      <span className="w-[2px] h-full bg-reactYellow absolute right-0 top-0 inline-flex -translate-y-[100%] group-hover:translate-y-0 duration-500"></span>
                    </button>
                  </form>
                </>
              )}
            </div>
            {/* ============== Close btn Start here ========== */}
            <div className="absolute w-32 h-32 top-1/2 -left-14 transform -translate-y-1/2">
              <div
                onClick={() => setEnquirySidebar(false)}
                className="w-full h-full flex items-center justify-center relative cursor-pointer"
              >
                <button className="w-20 h-20 rounded-full bg-reactYellowLight flex items-center justify-center text-black text-4xl font-titleFont font-extrabold hover:bg-reactYellow scale-100 hover:scale-110 duration-300">
                  <MdOutlineClose />
                </button>
                <span className="text-lg text-reactYellow font-semibold absolute -left-4 -rotate-90">
                  Close
                </span>
              </div>
            </div>
            {/* ============== Close btn End here ============ */}
          </motion.div>
        </div>
      )}
      {/* ==================== EnquirySidebar End here =================== */}
    </div>
  );
};

export default Navbar;
