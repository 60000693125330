import React from "react";

type Props = {
  title: string;
  titleBg: string;
  subTitle: string;
};

const SectionTitleDark = ({ title, titleBg, subTitle }: Props) => {
  return (
    <div className="w-full uppercase flex justify-center items-center relative mb-4 z-20">
      <p className="text-7xl xl:text-8xl text-zinc-800 font-bold">{titleBg}</p>
      <h1 className="w-full text-center absolute text-4xl font-semibold">
        {title} <span className="text-reactYellow">{subTitle}</span>
      </h1>
    </div>
  );
};

export default SectionTitleDark;
