import React from "react";
import { insightImg } from "../assets";

const Insights = () => {
  return (
    <div className="w-full py-16 bg-white text-black px-4">
      <div className="max-w-screen-xl mx-auto flex items-center justify-between gap-20">
        <div className="w-full lgl:w-1/2 flex flex-col gap-5">
          <p className="font-titleFont text-4xl font-medium">
            Did you know? Having a website isn't enough for your business.
          </p>
          <p className="text-base text-justify font-medium">
            At <span className="text-reactYellow font-semibold">ReactBD</span>{" "}
            Technology, we understand that having a website is not just about a
            bunch of code with graphics on the web. It's about creating a
            valuable digital space where visitors can interact with your goods
            and services, ultimately becoming your consumers. As a leading
            website design and development company, we offer cost-effective,
            top-notch, end-to-end solutions for all your web development needs.
            We take pride in our highly qualified and specialized team of
            developers in JavaScript, Reactjs, Nodejs, TypeScript,
            Redux-toolkit, Tailwindcss, HTML5, CSS3, Joomla, and WordPress. Our
            team is dedicated to helping you overcome any roadblocks and
            maximize your business exposure.
          </p>
          <p className="text-base text-justify font-medium">
            We understand that in today's digital world, the presence of a
            website is crucial for small and large businesses in every industry.
            That's why we are committed to providing professional and customized
            web development solutions to our clients. If you have any questions
            or doubts about your web development needs, do not hesitate to
            contact us. We are always ready to assist you.
          </p>
        </div>
        <div className="w-full lgl:w-1/2 hidden lgl:inline-flex relative">
          <img src={insightImg} loading="lazy" alt="insightImg" />
        </div>
      </div>
    </div>
  );
};

export default Insights;
