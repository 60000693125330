import React, { useState } from "react";
import { motion } from "framer-motion";
import { MdOutlineAdd } from "react-icons/md";
import { HiOutlineMinusSm } from "react-icons/hi";

interface Props {
  title: string;
  subTitle: string;
}

const FaqList = ({ title, subTitle }: Props) => {
  const [showFaq, setShowFaq] = useState(false);
  return (
    <div className="w-full h-auto overflow-y-hidden px-4">
      <p
        onClick={() => setShowFaq(!showFaq)}
        className="w-full py-2 px-4 bg-black text-gray-200 flex items-center font-medium justify-between text:sm md:text-lg cursor-pointer"
      >
        {title}
        <span className="text-xl">
          {showFaq ? <HiOutlineMinusSm /> : <MdOutlineAdd />}
        </span>
      </p>
      {showFaq && (
        <motion.p
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="border-[1px] lgl:border-2 border-black px-4 py-2 text-base font-light font-titleFont text-justify tracking-wide"
        >
          {subTitle}
        </motion.p>
      )}
    </div>
  );
};

export default FaqList;
