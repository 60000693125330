import React from "react";
import { motion } from "framer-motion";
import {
  developImgOne,
  developImgTwo,
  developImgThree,
  developImgFour,
} from "../../assets";
import BtnServices from "../designs/BtnServices";
import ParticlesDesign from "../designs/ParticlesDesign";

const WebDevelopment = () => {
  return (
    <div className="w-full bg-[#18181B] relative">
      <div className="w-full h-full">
        <div className="w-full h-full bg-services-bg bg-center bg-cover py-10 relative">
          <div className="max-w-screen-xl bg-black bg-opacity-90 mx-auto relative py-10 lgl:py-16 px-6">
            <div className="w-full h-full flex items-center gap-6">
              <div className="w-full lgl:w-1/2 h-full flex flex-col justify-between">
                <motion.h1
                  initial={{ x: -60, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    x: { type: "spring", stiffness: 60 },
                    ease: "easeIn",
                    duration: 0.5,
                  }}
                  className="text-zinc-300 uppercase text-sm font-medium"
                >
                  <span className="text-reactYellow hover:underline underline-offset-2 duration-200">
                    Home{" "}
                  </span>
                  / Web Development Services
                </motion.h1>
                <motion.div
                  initial={{ x: -30, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    x: { type: "spring", stiffness: 60 },
                    ease: "easeIn",
                    duration: 1,
                  }}
                >
                  <h1 className="text-[200px] font-extrabold text-reactYellow">
                    W
                  </h1>
                  <div className="-mt-[106px] md:-mt-[111px]">
                    <h2 className="text-3xl md:text-[40px] text-white font-semibold">
                      Web Development
                    </h2>
                    <p className="max-w-[600px] text-base text-zinc-200">
                      We are a top-notch web development company that can help
                      you define your brand and increase your revenue.
                      Full-Stack Web Development Services.
                    </p>
                  </div>
                </motion.div>
                <motion.div
                  initial={{ x: -30, opacity: 0 }}
                  whileInView={{ x: 0, opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    x: { type: "spring", stiffness: 60 },
                    ease: "easeIn",
                    duration: 1.5,
                  }}
                  className="flex flex-col md:flex-row ml-2 mt-16 items-start md:items-center gap-10 z-50"
                >
                  <BtnServices title="Portfolio" />
                  <BtnServices title="Order" />
                </motion.div>
              </div>
              {/* ================ Image part Start here ============== */}
              <div className="w-1/2 hidden lgl:grid grid-cols-2 gap-8">
                <motion.img
                  initial={{ x: -30, y: -60, opacity: 0 }}
                  whileInView={{ x: 0, y: 10, opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    delay: 0.4,
                    x: { type: "spring", stiffness: 120 },
                    y: { type: "spring", stiffness: 120 },
                    ease: "easeIn",
                    duration: 1,
                  }}
                  className="w-64"
                  src={developImgOne}
                  loading="lazy"
                  alt="developImgOne"
                />
                <motion.img
                  initial={{ x: 60, y: -60, opacity: 0 }}
                  whileInView={{ x: 0, y: 10, opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    delay: 0.4,
                    x: { type: "spring", stiffness: 120 },
                    y: { type: "spring", stiffness: 120 },
                    ease: "easeIn",
                    duration: 1,
                  }}
                  className="w-64"
                  src={developImgTwo}
                  loading="lazy"
                  alt="developImgTwo"
                />
                <motion.img
                  initial={{ x: -60, y: 60, opacity: 0 }}
                  whileInView={{ x: 0, y: 10, opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    delay: 0.4,
                    x: { type: "spring", stiffness: 120 },
                    y: { type: "spring", stiffness: 120 },
                    ease: "easeIn",
                    duration: 1,
                  }}
                  className="w-64"
                  src={developImgThree}
                  loading="lazy"
                  alt="developImgThree"
                />
                <motion.img
                  initial={{ x: 60, y: 60, opacity: 0 }}
                  whileInView={{ x: 0, y: 10, opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    delay: 0.4,
                    x: { type: "spring", stiffness: 120 },
                    y: { type: "spring", stiffness: 120 },
                    ease: "easeIn",
                    duration: 1,
                  }}
                  className="w-64"
                  src={developImgFour}
                  loading="lazy"
                  alt="developImgFour"
                />
              </div>
              {/* ================ Image part End here ================ */}
            </div>
            <div className="w-full h-full absolute top-0 left-0">
              <ParticlesDesign />
            </div>
          </div>
        </div>
        {/* ================== Content Start here ==================== */}
        <div className="w-full bg-black py-20 flex flex-col gap-20 px-4">
          <div className="max-w-4xl mx-auto flex flex-col gap-4">
            <h1 className="text-3xl font-medium underline underline-offset-8 decoration-[1px] decoration-reactYellow">
              Clean coding principles
            </h1>
            <p className="text-base tracking-wide">
              Experienced senior web developers with more than 10 years of
              experience with projects done for clients across the world can
              make your every web development dream come true.
            </p>
            <ul className="flex flex-col gap-2">
              <li className="flex items-center gap-2 text-zinc-300 font-medium font-titleFont hover:text-white duration-300 group">
                <span className="w-3 h-3 rounded-full inline-flex bg-reactYellowLight group-hover:bg-reactYellow duration-300"></span>
                Valid code
              </li>
              <li className="flex items-center gap-2 text-zinc-300 font-medium font-titleFont hover:text-white duration-300 group">
                <span className="w-3 h-3 rounded-full inline-flex bg-reactYellowLight group-hover:bg-reactYellow duration-300"></span>
                Fast loading time
              </li>
              <li className="flex items-center gap-2 text-zinc-300 font-medium font-titleFont hover:text-white duration-300 group">
                <span className="w-3 h-3 rounded-full inline-flex bg-reactYellowLight group-hover:bg-reactYellow duration-300"></span>
                Bug-free
              </li>
              <li className="flex items-center gap-2 text-zinc-300 font-medium font-titleFont hover:text-white duration-300 group">
                <span className="w-3 h-3 rounded-full inline-flex bg-reactYellowLight group-hover:bg-reactYellow duration-300"></span>
                User-friendly
              </li>
              <li className="flex items-center gap-2 text-zinc-300 font-medium font-titleFont hover:text-white duration-300 group">
                <span className="w-3 h-3 rounded-full inline-flex bg-reactYellowLight group-hover:bg-reactYellow duration-300"></span>
                SEO-friendly
              </li>
            </ul>
            <p>
              We work in line with the latest trends in new technologies and
              responsive design. We are always active participants at
              international web technologies conferences.
            </p>
            <ul className="flex flex-col gap-2">
              <li className="flex items-center gap-2 text-zinc-300 font-medium font-titleFont hover:text-white duration-300 group">
                <span className="w-3 h-3 rounded-full inline-flex bg-reactYellowLight group-hover:bg-reactYellow duration-300"></span>
                HTML5, CSS3, Tailwindcss Sass, Bootstrap, GraphQL
              </li>
              <li className="flex items-center gap-2 text-zinc-300 font-medium font-titleFont hover:text-white duration-300 group">
                <span className="w-3 h-3 rounded-full inline-flex bg-reactYellowLight group-hover:bg-reactYellow duration-300"></span>
                JavaScript, React.js, Next.js, Ract Native
              </li>
              <li className="flex items-center gap-2 text-zinc-300 font-medium font-titleFont hover:text-white duration-300 group">
                <span className="w-3 h-3 rounded-full inline-flex bg-reactYellowLight group-hover:bg-reactYellow duration-300"></span>
                Nodejs, MongoDB, Express.js, Firebase, Sanity.io
              </li>
              <li className="flex items-center gap-2 text-zinc-300 font-medium font-titleFont hover:text-white duration-300 group">
                <span className="w-3 h-3 rounded-full inline-flex bg-reactYellowLight group-hover:bg-reactYellow duration-300"></span>
                Figma, Adobe, Canva
              </li>
              <li className="flex items-center gap-2 text-zinc-300 font-medium font-titleFont hover:text-white duration-300 group">
                <span className="w-3 h-3 rounded-full inline-flex bg-reactYellowLight group-hover:bg-reactYellow duration-300"></span>
                Vercel, Netlify, Back4App
              </li>
            </ul>
          </div>
          <div className="max-w-4xl mx-auto flex flex-col gap-4">
            <h1 className="text-3xl font-medium underline underline-offset-8 decoration-[1px] decoration-reactYellow">
              Project management methodology for success
            </h1>
            <p className="text-base tracking-wide">
              Web developers from PopArt Studio team consists of work in line
              with methodology that results in the best performance. That is why
              we are proud of:
            </p>
            <ul className="flex flex-col gap-2">
              <li className="flex items-center gap-2 text-zinc-300 font-medium font-titleFont hover:text-white duration-300 group">
                <span className="w-3 h-3 rounded-full inline-flex bg-reactYellowLight group-hover:bg-reactYellow duration-300"></span>
                Precise task allocation
              </li>
              <li className="flex items-center gap-2 text-zinc-300 font-medium font-titleFont hover:text-white duration-300 group">
                <span className="w-3 h-3 rounded-full inline-flex bg-reactYellowLight group-hover:bg-reactYellow duration-300"></span>
                Narrow specialization
              </li>
              <li className="flex items-center gap-2 text-zinc-300 font-medium font-titleFont hover:text-white duration-300 group">
                <span className="w-3 h-3 rounded-full inline-flex bg-reactYellowLight group-hover:bg-reactYellow duration-300"></span>
                Each project or task has our QA engineers’ approval
              </li>
              <li className="flex items-center gap-2 text-zinc-300 font-medium font-titleFont hover:text-white duration-300 group">
                <span className="w-3 h-3 rounded-full inline-flex bg-reactYellowLight group-hover:bg-reactYellow duration-300"></span>
                Testing in all browsers and operating systems, and across
                various devices
              </li>
            </ul>
          </div>
        </div>
        {/* ================== Content End here ====================== */}
      </div>
    </div>
  );
};

export default WebDevelopment;
