import React from "react";
import { FaHome } from "react-icons/fa";
import { MdMarkEmailUnread } from "react-icons/md";
import { BsPhoneFill } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io";

const Left = () => {
  return (
    <div className="w-full lgl:w-1/3 font-titleFont flex flex-col gap-6">
      <p className="text-4xl font-semibold">Get in touch!</p>
      <p className="text-xl md:text-3xl font-semibold bg-clip-text bg-gradient-to-l from-reactYellow via-reactYellowLight to-green-400 text-transparent">
        Let's talk about your project
      </p>
      <p className="font-bodyFont text-base text-gray-300 font-medium">
        Thinking about a new project, a problem to solve, or just want to
        connect? Let's do it!
      </p>
      <p className="font-bodyFont text-base text-gray-300 font-medium">
        Use the form on this page or get in touch by other means.
      </p>
      <h1 className="text-xl tracking-wide text-gray-100 underline underline-offset-4 decoration-[1px] decoration-reactYellow">
        Contact Info
      </h1>
      <div className="flex flex-col gap-6 font-bodyFont">
        <div className="flex flex-col gap-2.5">
          <h2 className="uppercase text-lg font-medium text-reactYellowLight">
            Bangladesh:
          </h2>
          <p className="flex items-center gap-3 text-base text-zinc-300 hover:text-white hover:cursor-pointer transition-hover duration-300">
            <span className="text-reactYellow text-lg">
              <FaHome />
            </span>
            Rupnogor Abashik Area, <br />
            Mirpur-1, Dhaka.
          </p>
          <p className="flex items-center gap-3 text-base text-zinc-300 hover:text-white hover:cursor-pointer transition-hover duration-300">
            <span className="text-reactYellow text-lg">
              <MdMarkEmailUnread />
            </span>
            reactjsbd@gmail.com
          </p>
          <p className="flex items-center gap-3 text-base text-zinc-300 hover:text-white hover:cursor-pointer transition-hover duration-300">
            <span className="text-reactYellow text-lg">
              <BsPhoneFill />
            </span>
            +968 97859628
          </p>
          <p className="flex items-center gap-3 text-base text-zinc-300 hover:text-white hover:cursor-pointer transition-hover duration-300">
            <span className="text-reactYellow text-lg">
              <IoLogoWhatsapp />
            </span>
            +968 97859628
          </p>
        </div>
        <div className="flex flex-col gap-2.5">
          <p className="uppercase text-lg font-medium text-reactYellowLight">
            Oman:
          </p>
          <p className="flex items-center gap-3 text-base text-zinc-300 hover:text-white hover:cursor-pointer transition-hover duration-300">
            <span className="text-reactYellow text-lg">
              <FaHome />
            </span>
            Muscat Business Area, Ruwi, <br />
            Muscat, Sultanate of Oman
          </p>
          <p className="flex items-center gap-3 text-base text-zinc-300 hover:text-white hover:cursor-pointer transition-hover duration-300">
            <span className="text-reactYellow text-lg">
              <MdMarkEmailUnread />
            </span>
            reactjsbd@gmail.com
          </p>
          <p className="flex items-center gap-3 text-base text-zinc-300 hover:text-white hover:cursor-pointer transition-hover duration-300">
            <span className="text-reactYellow text-lg">
              <BsPhoneFill />
            </span>
            +968 97859628
          </p>
          <p className="flex items-center gap-3 text-base text-zinc-300 hover:text-white hover:cursor-pointer transition-hover duration-300">
            <span className="text-reactYellow text-lg">
              <IoLogoWhatsapp />
            </span>
            +968 97859628
          </p>
        </div>
      </div>
    </div>
  );
};

export default Left;
