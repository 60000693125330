import React, { FunctionComponent } from "react";
interface Props {
  Icon: React.ElementType;
  title: string;
  des: string;
}

const WhatWeOfferCard: FunctionComponent<Props> = ({ Icon, title, des }) => {
  return (
    <div className="w-full border h-96 bg-white shadow-serviceCard hover:shadow-none relative group overflow-hidden transform">
      <div className=" px-4 mdl:p-6 w-full h-full absolute z-10 flex flex-col justify-center items-center gap-5 top-0 group-hover:-top-3 transition-all duration-500">
        <Icon className="text-6xl group-hover:text-white" />
        <h3 className="text-2xl font-titleFont font-semibold group-hover:text-reactYellow">
          {title}
        </h3>
        <p className="text-base font-medium text-gray-700 text-center group-hover:text-white">
          {des}
        </p>
      </div>
      {/* ============ Design Start here ================ */}
      <span className="w-1/4 h-full bg-black inline-block absolute top-0 -translate-y-[100%] left-0/4 group-hover:translate-y-0 transition-transform duration-500"></span>
      <span className="w-1/4 h-full bg-black inline-block absolute top-0 translate-y-[100%] left-1/4 group-hover:translate-y-0 transition-transform duration-500"></span>
      <span className="w-1/4 h-full bg-black inline-block absolute top-0 -translate-y-[100%] left-2/4 group-hover:translate-y-0 transition-transform duration-500"></span>
      <span className="w-1/4 h-full bg-black inline-block absolute top-0 translate-y-[100%] left-3/4 group-hover:translate-y-0 transition-transform duration-500"></span>
      <div className="w-[85%] h-[90%] bg-service-bg group-hover:bg-opacity-10 absolute bg-no-repeat bg-contain top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-[43%] filter brightness-100 group-hover:brightness-25 duration-300"></div>
      {/* ============ Design End here ================== */}
    </div>
  );
};

export default WhatWeOfferCard;
