import React from "react";
import SectionTitle from "../designs/SectionTitle";
import FaqList from "./FaqList";

const Faq = () => {
  return (
    <div className="w-full bg-white text-black py-16">
      <SectionTitle
        title="Frequently Asked"
        subTitle="Questions"
        titleBg="Have Questions"
      />
      <div className="max-w-6xl mx-auto flex flex-col gap-2">
        <FaqList
          title="Why should you choose ReactBD Technology Web Design Company over others?"
          subTitle="ReactBD Technology is a web design agency steeped in experience,
          professionalism, and technology. we have built dozens of e-commerce websites, multi-lingual websites, healthcare, tourism, education, technology, real estate, and many more. When you choose us you are not only choosing a trusted web design company that creates high executive websites but
          also a full employment digital marketing agency that has a record of
          taking your business, brand into having a successful online existence."
        />
        <FaqList
          title="When There Are Freelancers Around What Is The Need To Hire A Web Design Agency?"
          subTitle="You have very little to worry about when you are hiring a web design agency. There will be a genial client service team to take care of your needs on one side and on the other side, the experienced professionals are ready to work on your project.You can expect a level of professionalism in every situation, from the first meeting through the assignment delivery and later on. More importantly, your website will be developed for google and other search engines. there will be many resources deployed for project completion, in an agency. So even if one or more people go on leave, as promised you will get your work completed on time."
        />
        <FaqList
          title="Will My Website Be Mobile-Friendly?"
          subTitle="Yes, the websites we design load easily on any device be it a mobile phone, tablet, or computer. While designing a website our developer highly focuses on mobile optimization. As per a survey, mostly quality leads come from mobile users."
        />
        <FaqList
          title="To Build Websites Do You Use All Web Design Platforms?"
          subTitle="There are many web design platforms and we have expertise in almost all of them. Although, we choose a platform only based on the client's preferences and the project type."
        />
        <FaqList
          title="Do You Remake The Websites?"
          subTitle="Yes, absolutely when we feel like it is necessary we do. For example, making a mobile site, the rule of thumb is to make navigation more thumb-friendly. similarly, convenience and facilitation of use are important for web design. We advise you to redesign when you find your website design is not appropriate as per trends and most important user behavior."
        />
        <FaqList
          title="What is the meaning of Responsive In Web Design?"
          subTitle="Responsive is the capacity of a website to adapt to screens and multiple devices. Earlier, the websites were made for only desktops and didn’t load properly on smartphones or tablets. Executing a responsive design can fix this problem and the user will be able to use your site smoothly on their mobile phone."
        />
        <FaqList
          title="Benefits Of A Responsive Web Design."
          subTitle="A responsive web design means your website is reachable on a mobile phone. To your site User can log on whenever they want and even while on the go, which increases the frequency of visits. It will increase your loading speed, low bounce rate, and offer a better user experience. Observing the fact that Google gives importance to user experience, being responsive can improve your site ranking."
        />
        <FaqList
          title="Are Your Web Designing SEO-Friendly?"
          subTitle="Yes, whether it is a corporate website or personal blog, keeping search engines in mind we design a website. Search engines are as much important as your users are. There is no point in having a website when it is not visible on the search engine. As ReactBD is a web Developing company, we are proud that our website is optimised for Bing crawlers and Google."
        />
      </div>
    </div>
  );
};

export default Faq;
