import React from "react";
import {
  SiTailwindcss,
  SiJavascript,
  SiTypescript,
  SiFirebase,
  SiExpress,
  SiRedux,
  SiNextdotjs,
  SiVisualstudiocode,
  SiFigma,
} from "react-icons/si";
import { DiMongodb } from "react-icons/di";
import { FaReact, FaNodeJs } from "react-icons/fa";
import SectionTitleDark from "../designs/SectionTitleDark";
import TechItem from "./TechItem";

const Tech = () => {
  return (
    <div className="py-20 px-4 bg-gradient-to-t from-[#212121] via-[#212121] to-black relative">
      <SectionTitleDark
        title="Technologies"
        subTitle="We use"
        titleBg="Technologies"
      />
      <div className="max-w-6xl mx-auto ">
        <p className="text-base text-gray-200 text-center">
          We have worked with top-notch businesses across various industries to
          build scalable softwares, mobile apps, e-commerce websites, custom
          marketplaces, and web solutions that are specifically tailored to suit
          their changing business requirements using the latest and proven
          technologies.
        </p>
      </div>
      <div className="w-full h-full grid grid-cols-4 gap-10 md:grid-cols-6 lgl:grid-cols-8 place-items-center overflow-hidden mt-12">
        <div className="hidden lgl:inline-flex"></div>
        <div className="flex flex-col items-center gap-10 col-span-2 lgl:col-span-1">
          <TechItem
            techTitle="JavaScript"
            Icon={SiJavascript}
            className="text-5xl text-yellow-400"
          />
          <div className="flex flex-col items-center">
            <TechItem
              techTitle="Node Js"
              Icon={FaNodeJs}
              className="text-5xl text-[#6cc24a]"
            />
          </div>
        </div>
        <div className="flex flex-col items-center gap-10 col-span-2 lgl:col-span-1">
          <TechItem
            techTitle="Reactjs"
            Icon={FaReact}
            className="text-5xl text-[#61DBFB]"
          />
          <div className="flex flex-col items-center">
            <TechItem
              techTitle="TypeScript"
              Icon={SiTypescript}
              className="text-5xl text-[#007acc]"
            />
          </div>
        </div>
        <div className="flex flex-col items-center gap-10 col-span-2 lgl:col-span-1">
          <TechItem
            techTitle="MongoDB"
            Icon={DiMongodb}
            className="text-5xl text-[#589636]"
          />
          <div className="flex flex-col items-center">
            <TechItem
              techTitle="Firebase"
              Icon={SiFirebase}
              className="text-5xl text-[#FFA611]"
            />
          </div>
        </div>
        <div className="flex flex-col items-center gap-10 col-span-2 lgl:col-span-1">
          <TechItem
            techTitle="Expressjs"
            Icon={SiExpress}
            className="text-5xl text-[#303030]"
          />
          <div className="flex flex-col items-center">
            <TechItem
              techTitle="Redux Toolkit"
              Icon={SiRedux}
              className="text-5xl text-[#764abc]"
            />
          </div>
        </div>
        <div className="flex flex-col items-center gap-10 col-span-2 lgl:col-span-1">
          <TechItem
            techTitle="Nextjs"
            Icon={SiNextdotjs}
            className="text-5xl text-[#a8b9c0]"
          />
          <div className="flex flex-col items-center">
            <TechItem
              techTitle="VS Code"
              Icon={SiVisualstudiocode}
              className="text-5xl text-[#0078d7]"
            />
          </div>
        </div>
        <div className="flex flex-col items-center gap-10 col-span-2 lgl:col-span-1">
          <TechItem
            techTitle="Tailwindcss"
            Icon={SiTailwindcss}
            className="text-5xl text-[#38bdf8]"
          />
          <div className="flex flex-col items-center">
            <TechItem
              techTitle="Figma"
              Icon={SiFigma}
              className="text-5xl text-[#9dfbe7]"
            />
          </div>
        </div>
      </div>
      {/* ================== Designs Start here =================== */}
      <div className="w-full h-full absolute top-0 left-0 grid grid-cols-8 place-items-center overflow-hidden">
        <div className="w-full h-full relative border-r-[1px] border-gray-800">
          <span className="w-[1px] h-full bg-transparent inline-flex absolute -right-[1px] after:w-full after:h-16 after:bg-gradient-to-t after:from-white after:to-reactYellow animate-run animation-delay-600"></span>
        </div>
        <div className="w-full h-full relative border-r-[1px] border-gray-800">
          <span className="w-[1px] h-full bg-transparent inline-flex absolute -right-[1px] after:w-full after:h-16 after:bg-gradient-to-t after:from-white after:to-reactYellow animate-run animation-delay-700"></span>
        </div>
        <div className="w-full h-full relative border-r-[1px] border-gray-800">
          <span className="w-[1px] h-full bg-transparent inline-flex absolute -right-[1px] after:w-full after:h-16 after:bg-gradient-to-t after:from-white after:to-reactYellow animate-run animation-delay-800"></span>
        </div>
        <div className="w-full h-full relative border-r-[1px] border-gray-800">
          <span className="w-[1px] h-full bg-transparent inline-flex absolute -right-[1px] after:w-full after:h-16 after:bg-gradient-to-t after:from-white after:to-reactYellow animate-run animation-delay-900"></span>
        </div>
        <div className="w-full h-full relative border-r-[1px] border-gray-800">
          <span className="w-[1px] h-full bg-transparent inline-flex absolute -right-[1px] after:w-full after:h-16 after:bg-gradient-to-t after:from-white after:to-reactYellow animate-run animation-delay-1000"></span>
        </div>
        <div className="w-full h-full relative border-r-[1px] border-gray-800">
          <span className="w-[1px] h-full bg-transparent inline-flex absolute -right-[1px] after:w-full after:h-16 after:bg-gradient-to-t after:from-white after:to-reactYellow animate-run animation-delay-1100"></span>
        </div>
        <div className="w-full h-full relative border-r-[1px] border-gray-800">
          <span className="w-[1px] h-full bg-transparent inline-flex absolute -right-[1px] after:w-full after:h-16 after:bg-gradient-to-t after:from-white after:to-reactYellow animate-run animation-delay-1200"></span>
        </div>
      </div>
      {/* ================== Designs End here ===================== */}
    </div>
  );
};

export default Tech;
