import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { HiArrowNarrowRight } from "react-icons/hi";
import ListItems from "./listItems/ListItems";

const Banner = () => {
  // ================ Input value Start here =================
  const [clientname, setClientname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  // ================ Input value End here ===================
  // ================ Error Messages Start here ==============
  const [clientnameErr, setClientnameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [phoneNumberErr, setPhoneNumberErr] = useState("");
  const [messageErr, setMessageErr] = useState("");
  // ================ Error Messages End here ================

  // ================ onChange value Start here ===============
  const handleClientname = (e: any) => {
    setClientname(e.target.value);
    setClientnameErr("");
  };
  const handleEmail = (e: any) => {
    setEmail(e.target.value);
    setEmailErr("");
  };
  const handlePhoneNumber = (e: any) => {
    setPhoneNumber(e.target.value);
    setPhoneNumberErr("");
  };
  const handleMessage = (e: any) => {
    setMessage(e.target.value);
    setMessageErr("");
  };

  // Email validation start
  const emailValidation = (email: any) => {
    return String(email)
      .toUpperCase()
      .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  };
  // ================ onChange value End here =================
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!clientname) {
      setClientnameErr("Enter your Name");
    }
    if (!email) {
      setEmailErr("Enter your Email");
    } else {
      if (!emailValidation(email)) {
        setEmailErr("Enter a valid Email");
      }
    }
    if (!phoneNumber) {
      setPhoneNumberErr("Enter your Phone Number");
    }
    if (!message) {
      setMessageErr("Enter your Messages");
    }
    if (
      clientname &&
      email &&
      emailValidation(email) &&
      phoneNumber &&
      message
    ) {
      setSuccessMsg(
        `Thank you dear ${clientname} for your valuable time. Our Marketing team will join with you shortly and additional details will send to you by your email at ${email}. Have a great day ahead.`
      );
    }
  };

  // =============== Free Consultation Start here ==================
  const inputRef = useRef<HTMLInputElement>(null);
  function handleFreeConsultion() {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);
  // =============== Free Consultation End here ====================
  return (
    <div className="w-full min-h-[500px] bg-banner-bg px-4">
      <div className="max-w-screen-xl mx-auto flex flex-col gap-6 lgl:flex-row justify-between items-center py-10">
        {/* ==================== Left Banner Start here ===================== */}
        <div className="w-full lgl:w-1/2 flex flex-col gap-2">
          <h1>
            <span className="text-reactYellow text-3xl">
              ReactBD web development company_
            </span>
          </h1>
          <p className="font-medium md:text-justify text-xl mb-4 text-gray-50">
            is a premier that empowers businesses to establish and strengthen
            their online brand presence. Our dynamic digital strategy caters to
            both startups and enterprises, providing customized and robust web
            solutions. Choose ReactBD to thrive in the ever-evolving digital
            space with a trusted and reliable web development partner. Let's
            find some of our key features:
          </p>

          <ul className="flex flex-col gap-2">
            <ListItems title="Affordable Multivendor/Marketplace Website Development Packages" />
            <ListItems title="SEO-friendly website development that delivers a high audience engagement rate and more." />
            <ListItems title="We develop a website where visitor turns into a customer." />
            <ListItems title="Free warranty for a Year  ." />
            <ListItems title="Cloud Solutions." />
            <ListItems title="Third-Party Integrations (CRM and more)." />
            <ListItems title="Website for both B2B & B2C." />
          </ul>
          <button
            onClick={handleFreeConsultion}
            className="w-56 h-10 bg-reactYellow text-gray-800 uppercase font-bold mt-6 hover:bg-[#212121] hover:text-gray-50 border-[1px] border-transparent hover:border-gray-400 duration-300 rounded-md animate-pulse scale-100 hover:scale-110"
          >
            Free Consultation
          </button>
        </div>
        {/* ==================== Left Banner End here ======================= */}
        {/* ==================== Right Banner Start here ==================== */}

        <div className="w-full lgl:w-1/2 flex lgl:justify-end">
          <form className="max-w-[500px] bg-[#212121] p-4 mdl:p-10">
            <p className="text-2xl mdl:text-3xl text-reactYellow font-bold">
              Hola! We Love Talking With Our Site Visitors
            </p>
            <p className="text-base text-gray-50 font-medium mt-2">
              Leave your query below and in a moment our Web Development Expert
              will be in touch with you
            </p>
            {successMsg ? (
              <motion.p
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8 }}
                className="text-lg text-green-400 pr-10 leading-6 font-titleFont font-medium mt-4"
              >
                {successMsg}
              </motion.p>
            ) : (
              <div className="flex flex-col gap-6 py-6">
                <input
                  ref={inputRef}
                  onChange={handleClientname}
                  className="bannerInputField"
                  type="text"
                  placeholder="Your Full Name*"
                />
                {clientnameErr && (
                  <p className="w-full text-red-500 px-4 -mt-3 text-sm font-medium">
                    <span className="font-bold italic mr-1">!</span>{" "}
                    {clientnameErr}
                  </p>
                )}
                <input
                  onChange={handleEmail}
                  className="bannerInputField"
                  type="email"
                  placeholder="Your Email Address*"
                />
                {emailErr && (
                  <p className="w-full text-red-500 px-4 -mt-3 text-sm font-medium">
                    <span className="font-bold italic mr-1">!</span> {emailErr}
                  </p>
                )}
                <input
                  onChange={handlePhoneNumber}
                  className="bannerInputField"
                  type="text"
                  placeholder="Your Phone Number*"
                />
                {phoneNumberErr && (
                  <p className="w-full text-red-500 px-4 -mt-3 text-sm font-medium">
                    <span className="font-bold italic mr-1">!</span>{" "}
                    {phoneNumberErr}
                  </p>
                )}
                <textarea
                  onChange={handleMessage}
                  className="bannerInputField py-4 resize-none"
                  placeholder="Brief Your Projects"
                ></textarea>
                {messageErr && (
                  <p className="w-full text-red-500 px-4 -mt-3 text-sm font-medium">
                    <span className="font-bold italic mr-1">!</span>{" "}
                    {messageErr}
                  </p>
                )}
                <button
                  onClick={handleSubmit}
                  className="text-base font-titleFont font-semibold uppercase text-reactYellow border-[1px] border-gray-600 w-full h-12 flex items-center gap-1 justify-center relative group overflow-hidden active:bg-reactYellow active:text-white"
                >
                  Submit
                  <span className="text-sm translate-x-.5 group-hover:translate-x-2 transition-transform duration-300">
                    <HiArrowNarrowRight />
                  </span>
                  <span className="w-full h-[1px] bg-reactYellow absolute left-0 top-0 inline-flex -translate-x-[100%] group-hover:translate-x-0 duration-300"></span>
                  <span className="w-full h-[1px] bg-reactYellow absolute left-0 bottom-0 inline-flex translate-x-[100%] group-hover:translate-x-0 duration-300"></span>
                  <span className="w-[2px] h-full bg-reactYellow absolute left-0 top-0 inline-flex translate-y-[100%] group-hover:translate-y-0 duration-500"></span>
                  <span className="w-[2px] h-full bg-reactYellow absolute right-0 top-0 inline-flex -translate-y-[100%] group-hover:translate-y-0 duration-500"></span>
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
      {/* ==================== Right Banner End here ====================== */}
    </div>
  );
};

export default Banner;
