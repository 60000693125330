import React from "react";

const AboutUs = () => {
  return (
    <div className="w-full h-auto py-10 bg-white">
      <div className="w-full">
        <div className="w-full h-auto py-20 bg-reactYellow bg-line-bg bg-no-repeat bg-cover bg-center text-black">
          <div className="max-w-screen-xl mx-auto">
            <h1 className="text-4xl text-center font-medium">
              We've helped businesses increase their revenue on an average by
              90% in their first year with us!
            </h1>
            <div className="max-w-6xl mx-auto flex items-center gap-10 mt-16">
              <div className="w-full bg-white group">
                <div className="w-full bg-reactYellowLight hover:bg-reactYellow border-[2px] border-zinc-700 flex flex-col gap-2 items-center justify-center py-8 transform -translate-x-3 -translate-y-3 group-hover:translate-x-0 group-hover:translate-y-0 transition-all ease-in-out duration-500 group-hover:border-black">
                  <h1 className="text-6xl font-titleFont font-semibold">100</h1>
                  <p className="text-lg font-medium">
                    Satisfied Clients Across the Globe
                  </p>
                </div>
              </div>
              <div className="w-full bg-white hover:bg-transparent group">
                <div className="w-full bg-reactYellowLight hover:bg-reactYellow border-[2px] border-zinc-700 flex flex-col gap-2 items-center justify-center py-8 transform -translate-x-3 -translate-y-3 group-hover:translate-x-0 group-hover:translate-y-0 transition-all ease-in-out duration-500 group-hover:border-black">
                  <h1 className="text-6xl font-titleFont font-semibold">250</h1>
                  <p className="text-lg font-medium">
                    Projects Delivered Successfully
                  </p>
                </div>
              </div>
              <div className="w-full bg-white group">
                <div className="w-full bg-reactYellowLight hover:bg-reactYellow border-[2px] border-zinc-700 flex flex-col gap-2 items-center justify-center py-8 transform -translate-x-3 -translate-y-3 group-hover:translate-x-0 group-hover:translate-y-0 transition-all ease-in-out duration-500 group-hover:border-black">
                  <h1 className="text-6xl font-titleFont font-semibold">20</h1>
                  <p className="text-lg font-medium">
                    Experts Under the Same Roof
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
