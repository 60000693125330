import {
  educare,
  orebiShopping,
  reactBD,
  amazonClone,
  cyberBlog,
  dynamicPortfolio,
} from "../assets/index";

export const servicesList = [
  {
    _id: 1000,
    title: "Select Services*",
  },
  {
    _id: 1001,
    title: "Web Development",
  },
  {
    _id: 1002,
    title: "eCommeerce Solutions",
  },
  {
    _id: 1003,
    title: "Mobile App Development",
  },
  {
    _id: 1004,
    title: "Digital Marketing",
  },
  {
    _id: 1005,
    title: "UI/UX Design",
  },
  {
    _id: 1006,
    title: "Graphics Design",
  },
  {
    _id: 1007,
    title: "Others",
  },
];
export const footerServicesList = [
  {
    _id: 2001,
    title: "Web Development",
  },
  {
    _id: 2002,
    title: "eCommeerce Solutions",
  },
  {
    _id: 2003,
    title: "Mobile App Development",
  },
  {
    _id: 2004,
    title: "Digital Marketing",
  },
  {
    _id: 2005,
    title: "UI/UX Design",
  },
  {
    _id: 2006,
    title: "Graphics Design",
  },
  {
    _id: 2007,
    title: "Others",
  },
];
export const footerQuickLinks = [
  {
    _id: 3001,
    title: "Home",
  },
  {
    _id: 3002,
    title: "About Us",
  },
  {
    _id: 3003,
    title: "Our Services",
  },
  {
    _id: 3004,
    title: "Projects",
  },
  {
    _id: 3005,
    title: "Career",
  },
  {
    _id: 3006,
    title: "Blog",
  },
  {
    _id: 3007,
    title: "Contact Us",
  },
  {
    _id: 3008,
    title: "Privacy & Policy",
  },
];
export const projectList = [
  {
    _id: 9001,
    title: "Educare English Learning Academy",
    link: "https://www.educareenglishlearningacademy.com/",
    image: educare,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, explicabo! Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum, cumque.",
  },
  {
    _id: 9002,
    title: "Cyber Security Blog",
    link: "https://www.noormohmmad.com/",
    image: cyberBlog,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, explicabo! Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum, cumque.",
  },
  {
    _id: 9003,
    title: "Amazon Clone 2.0",
    link: "https://amazonclone.reactbd.com/",
    image: amazonClone,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, explicabo! Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum, cumque.",
  },
  {
    _id: 9004,
    title: "ReactBD Demo",
    link: "https://reactbd.com/",
    image: reactBD,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, explicabo! Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum, cumque.",
  },
  {
    _id: 9005,
    title: "Orebi Online Shopping",
    link: "https://orebishopping.reactbd.com/",
    image: orebiShopping,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, explicabo! Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum, cumque.",
  },
  {
    _id: 9006,
    title: "Dynamic Portfolio",
    link: "https://portfolioone.reactbd.com/",
    image: dynamicPortfolio,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, explicabo! Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum, cumque.",
  },
];
