import React from "react";
import { logoDarkImg } from "../../assets/index";
import { Link, NavLink } from "react-router-dom";
import { BsTelephoneFill, BsEnvelopeFill } from "react-icons/bs";
import { motion } from "framer-motion";

const ServicesNav = () => {
  // const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="w-full bg-black h-20 sticky top-0 z-50 px-4">
      <div className="max-w-screen-xl h-full mx-auto flex gap-6 lg:gap-0 justify-between items-center">
        <Link to="/">
          <div>
            <img className="w-44" src={logoDarkImg} loading="lazy" alt="logo" />
          </div>
        </Link>
        <div
          // onClick={() => setShowMenu(true)}
          className="w-6 h-5 flex flex-col justify-between items-center lg:hidden text-4xl text-reactYellow cursor-pointer overflow-hidden group"
        >
          {/* <HiOutlineMenu /> */}
          <span className="w-full h-[2px] bg-reactYellow inline-flex transform group-hover:translate-x-2 transition-all ease-in-out duration-300"></span>
          <span className="w-full h-[2px] bg-reactYellow inline-flex transform translate-x-3 group-hover:translate-x-0 transition-all ease-in-out duration-300"></span>
          <span className="w-full h-[2px] bg-reactYellow inline-flex transform translate-x-1 group-hover:translate-x-3 transition-all ease-in-out duration-300"></span>
        </div>
        {/* ========================= Small NavBar Start here ====================== */}

        {/* ========================= Small NavBar End here ======================== */}
        <div className="w-full lg:w-2/3 hidden lg:flex justify-between item-center">
          <ul className="flex items-center gap-6 xl:gap-8 uppercase text-sm font-titleFont font-medium tracking-wider text-gray-300">
            <NavLink to="/">
              <motion.li
                initial={{ y: -6, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                  y: { type: "spring", stiffness: 60 },
                  ease: "easeIn",
                  duration: 0.2,
                }}
                className="hover:text-white cursor-pointer duration-300"
              >
                Home
              </motion.li>
            </NavLink>
            <NavLink to="/services/webdevelopment">
              <motion.li
                initial={{ y: -6, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                  y: { type: "spring", stiffness: 60 },
                  ease: "easeIn",
                  duration: 0.5,
                }}
                className="hover:text-white cursor-pointer duration-300"
              >
                Web Development
              </motion.li>
            </NavLink>
            <NavLink to="/services/webdesign">
              <motion.li
                initial={{ y: -6, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                  y: { type: "spring", stiffness: 60 },
                  ease: "easeIn",
                  duration: 0.8,
                }}
                className="hover:text-white cursor-pointer duration-300"
              >
                Web Design
              </motion.li>
            </NavLink>
            <NavLink to="/services/seo">
              <motion.li
                initial={{ y: -6, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                  y: { type: "spring", stiffness: 60 },
                  ease: "easeIn",
                  duration: 1.3,
                }}
                className="hover:text-white cursor-pointer duration-300"
              >
                SEO
              </motion.li>
            </NavLink>
            <NavLink to="/services/graphicdesign">
              <motion.li
                initial={{ y: -6, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                  y: { type: "spring", stiffness: 60 },
                  ease: "easeIn",
                  duration: 1,
                }}
                className="hover:text-white cursor-pointer duration-300"
              >
                Graphic Design
              </motion.li>
            </NavLink>
          </ul>
          <div className="flex items-center gap-4">
            <motion.span
              initial={{ y: -6, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                y: { type: "spring", stiffness: 60 },
                ease: "easeIn",
                duration: 1.5,
              }}
              className="w-10 h-10 bg-reactYellow text-black text-xl rounded-full inline-flex items-center justify-center hover:bg-reactYellowLight cursor-pointer duration-300"
            >
              <BsTelephoneFill />
            </motion.span>
            <motion.span
              initial={{ y: -8, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                y: { type: "spring", stiffness: 60 },
                ease: "easeIn",
                duration: 2,
              }}
              className="w-10 h-10 bg-reactYellow text-black text-xl rounded-full inline-flex items-center justify-center hover:bg-reactYellowLight cursor-pointer duration-300"
            >
              <BsEnvelopeFill />
            </motion.span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesNav;
