import React from "react";

type Props = {
  title: string;
  titleBg: string;
  subTitle: string;
};

const SectionTitle = ({ title, titleBg, subTitle }: Props) => {
  return (
    <div className="w-full uppercase flex justify-center items-center relative mb-4">
      <p className="text-4xl lgl:text-7xl xl:text-8xl text-[#f7f7f7] font-bold">
        {titleBg}
      </p>
      <h1 className="text-xl md:text-3xl lgl:text-4xl font-semibold absolute">
        {title} <span className="text-reactYellow">{subTitle}</span>
      </h1>
    </div>
  );
};

export default SectionTitle;
