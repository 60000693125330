import React from "react";
import { HiOutlineCode, HiShoppingBag } from "react-icons/hi";
import { SiAntdesign, SiWritedotas } from "react-icons/si";
import { GiWorld } from "react-icons/gi";
import { MdCastConnected } from "react-icons/md";
import SectionTitle from "../designs/SectionTitle";
import WhatWeOfferCard from "./WhatWeOfferCard";

const WhatWeOffer = () => {
  return (
    <div className="w-full bg-white text-black py-10">
      <SectionTitle title="What" subTitle="We Offer" titleBg="What We Offer" />
      <div className="max-w-screen-xl mx-auto">
        <h3 className="text-base md:text-lg text-center tracking-wide px-4">
          To us, it's not just work - we take pride in the solutions we deliver.
          We encourage each other to achieve excellence in all endeavours and
          aren't satisfied until projects meet our own personal high standards.
          Please take a look at Our Process to see how we will bring your
          project into existance.
        </h3>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 lgl:gap-12 mt-24 px-4 xl:px-10">
          <WhatWeOfferCard
            Icon={HiOutlineCode}
            title="Website Development"
            des="We use advanced tools and tech to create visually stunning, functional
          websites tailored to your unique business needs. Trust us to help you
          achieve your digital goals."
          />
          <WhatWeOfferCard
            Icon={HiShoppingBag}
            title="Ecommerce Solutions"
            des="With our best-customized eCommerce website design solutions. We try to deliver a high-quality user interface to visitors that encourage them to buy the products with low cost ecommerce website."
          />
          <WhatWeOfferCard
            Icon={SiAntdesign}
            title="UI/UX Developer"
            des="Provide captivating User interface and smooth & seamless navigation to the online users to make them your regular customers."
          />
          <WhatWeOfferCard
            Icon={MdCastConnected}
            title="SEO Solutions"
            des="We use advanced tools and tech to create visually stunning, functional
          websites tailored to your unique business needs. Trust us to help you
          achieve your digital goals."
          />
          <WhatWeOfferCard
            Icon={GiWorld}
            title="Domain/Hosting"
            des="With our best-customized eCommerce website design solutions. We try to deliver a high-quality user interface to visitors that encourage them to buy the products with low cost ecommerce website."
          />
          <WhatWeOfferCard
            Icon={SiWritedotas}
            title="Content Writing"
            des="Provide captivating User interface and smooth & seamless navigation to the online users to make them your regular customers."
          />
        </div>
      </div>
    </div>
  );
};

export default WhatWeOffer;
